import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import { PageHeader } from '../../components/design';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <PageHeader
          title="Contact"
          subtitle="Vestibulum morbi blandit cursus risus at ultrices mi tempus imperdiet."
        />
        <section className="section bg-gray-100">
          <form
            className="lg:w-1/2 bg-white shadow-lg section-sm"
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="form-item">
              <label htmlFor="name">Your name</label>
              <input
                className="form-input"
                type="text"
                name="name"
                onChange={this.handleChange}
                id="name"
                required
              />
            </div>
            <div className="form-item">
              <label htmlFor="email">Email</label>
              <input
                className="form-input"
                type="email"
                name="email"
                onChange={this.handleChange}
                id="email"
                required
              />
            </div>
            <div className="form-item">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-textarea "
                name="message"
                onChange={this.handleChange}
                id="message"
                required
              />
            </div>
            <button className="btn" type="submit">
              Send Message
            </button>
          </form>
        </section>
      </Layout>
    );
  }
}
